import "./Notification.css";
function Notification(props) {
  return (
    <div className="swal-mini">
      <div style={{ padding: "10px" }}>
        <div>
          <p>{props.title}!</p>
        </div>
        <div>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default Notification;
