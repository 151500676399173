import "./ItemListContainer.css";
import React, { useState, useEffect } from "react";
import ItemList from "../ItemList/ItemList";
import { getItems, getItemsCateg } from "../../../services/db";
import Spinner from "../Spinner/Spinner";
import { useParams } from "react-router-dom";

function ItemListContainer(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { query } = useParams();
  const searchQuery = query || "";
  useEffect(() => {
    setLoading(true);
    if (props.cat === undefined) {
      getItems().then((resp) => {
        setProducts(resp);
        setLoading(false);
      });
    }
    if (props.cat !== undefined) {
      (async () => {
        try {
          const resp = await getItemsCateg(props.cat);
          setProducts(resp);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      })();
    }
  }, [props.cat]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="container-fluid cardDiv row no-gutters">
          {searchQuery && searchQuery === "" ? (
            products
              .filter(
                (product) =>
                  product.title
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) || // <-- Agrega "&&" para agregar una condición adicional
                  product.categ
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.descr
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.descrPlus
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.miniDescr
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    )
              )
              .map((product) => (
                <div
                  key={product?.id}
                  className="col-sm-6 col-md-4 col-lg-2 col-xl-2 justify-content-center d-flex"
                >
                  <ItemList
                    key={product?.id}
                    id={product?.id}
                    title={product?.title}
                    img={product?.img}
                    categ={product?.categ}
                    model={product?.model}
                    descr={product?.descr}
                    miniDescr={product?.miniDescr}
                    linkTo={true}
                  />
                </div>
              ))
          ) : searchQuery.trim() !== "" && props.cat === undefined ? (
            <>
              {products.filter(
                (product) =>
                  // Agrega aquí la condición que debe cumplir un producto para considerarse encontrado
                  // Por ejemplo, si quieres verificar si algún producto tiene una coincidencia de búsqueda
                  product.title
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) || // <-- Agrega "&&" para agregar una condición adicional
                  product.categ
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.descr
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.descrPlus
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    ) ||
                  product.miniDescr
                    .toLowerCase()
                    .replace(/[^\w\s-]/gi, "")
                    .includes(
                      decodeURIComponent(
                        searchQuery.toLowerCase().replace(/\+/g, " ")
                      ).replace(/[^\w\s-]/gi, "")
                    )
              ).length === 0 ? (
                // No se encontraron productos
                <div
                  className="container"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "100px",
                  }}
                >
                  <h1 style={{ color: "white" }}>
                    No se encontraron coincidencias
                  </h1>
                </div>
              ) : (
                // Se encontraron productos
                products
                  .filter(
                    (product) =>
                      product.title
                        .toLowerCase()
                        .replace(/[^\w\s-]/gi, "")
                        .includes(
                          decodeURIComponent(
                            searchQuery.toLowerCase().replace(/\+/g, " ")
                          ).replace(/[^\w\s-]/gi, "")
                        ) || // <-- Agrega "&&" para agregar una condición adicional
                      product.categ
                        .toLowerCase()
                        .replace(/[^\w\s-]/gi, "")
                        .includes(
                          decodeURIComponent(
                            searchQuery.toLowerCase().replace(/\+/g, " ")
                          ).replace(/[^\w\s-]/gi, "")
                        ) ||
                      product.descr
                        .toLowerCase()
                        .replace(/[^\w\s-]/gi, "")
                        .includes(
                          decodeURIComponent(
                            searchQuery.toLowerCase().replace(/\+/g, " ")
                          ).replace(/[^\w\s-]/gi, "")
                        ) ||
                      product.descrPlus
                        .toLowerCase()
                        .replace(/[^\w\s-]/gi, "")
                        .includes(
                          decodeURIComponent(
                            searchQuery.toLowerCase().replace(/\+/g, " ")
                          ).replace(/[^\w\s-]/gi, "")
                        ) ||
                      product.miniDescr
                        .toLowerCase()
                        .replace(/[^\w\s-]/gi, "")
                        .includes(
                          decodeURIComponent(
                            searchQuery.toLowerCase().replace(/\+/g, " ")
                          ).replace(/[^\w\s-]/gi, "")
                        )
                  )
                  .map((product) => (
                    <div
                      key={product?.id}
                      className="col-sm-6 col-md-4 col-lg-2 col-xl-2 justify-content-center d-flex"
                    >
                      <ItemList
                        key={product?.id}
                        id={product?.id}
                        title={product?.title}
                        img={product?.img}
                        categ={product?.categ}
                        model={product?.model}
                        descr={product?.descr}
                        miniDescr={product?.miniDescr}
                        linkTo={true}
                      />
                    </div>
                  ))
              )}
            </>
          ) : searchQuery.trim() === "" && props.cat !== undefined ? (
            products
              .filter((product) => product.categ === props.cat)
              .map((product) => (
                <div
                  key={product?.id}
                  className="col-sm-6 col-md-4 col-lg-2 col-xl-2 justify-content-center d-flex"
                >
                  <ItemList
                    key={product?.id}
                    id={product?.id}
                    title={product?.title}
                    img={product?.img}
                    categ={product?.categ}
                    model={product?.model}
                    descr={product?.descr}
                    miniDescr={product?.miniDescr}
                    linkTo={true}
                  />
                </div>
              ))
          ) : (
            products.map((product) => (
              <div
                key={product?.id}
                className="col-sm-6 col-md-4 col-lg-2 col-xl-2 justify-content-center d-flex"
              >
                <ItemList
                  key={product?.id}
                  id={product?.id}
                  title={product?.title}
                  img={product?.img}
                  categ={product?.categ}
                  model={product?.model}
                  descr={product?.descr}
                  miniDescr={product?.miniDescr}
                  linkTo={true}
                />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default ItemListContainer;
