import React, { createContext, useContext, useState } from "react";

const ItemModelContext = createContext();

export const ItemModelProvider = ({ children }) => {
  const [itemModel, setItemModel] = useState("");

  return (
    <ItemModelContext.Provider value={{ itemModel, setItemModel }}>
      {children}
    </ItemModelContext.Provider>
  );
};

export const useItemModel = () => {
  return useContext(ItemModelContext);
};
