import "./tutorial.css";
import VideoPlayer from "../VideoPlayer/VideoPlayer.jsx";
function Tutorial(props) {
  return (
    <div
      className="container tutorial-container"
      
    >
      <h3
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: "bolder",
          paddingTop: "10px",
        }}
      >
        {props.title}
      </h3>
      <VideoPlayer style={{ textAlign: "center" }} videoId={props.videoId} />
    </div>
  );
}

export default Tutorial;
