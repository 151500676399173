import React, { useEffect, useState } from "react";
import CarouselObj from "../Carousel/Carousel";
import { getRandomItems } from "../../../services/db";
import Spinner from "../Spinner/Spinner";

import "./Home.css";

function Home() {
  const [imgList, setImgList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    obtenerItemsAleatorios();
  }, []);
  async function obtenerItemsAleatorios() {
    try {
      const randomItems = await getRandomItems();

      // Generar un índice aleatorio para el className
      const getRandomIndex = () => Math.floor(Math.random() * 2);

      // Extraer los campos necesarios y colocarlos en el formato deseado
      const formattedItems = randomItems.map((item) => ({
        src: item.img0,
        id: item.id,
        className: ["text-start", "text-end"][getRandomIndex()],
        altText: item.title,
        caption: item.title,
        description: item.descr,
      }));

      setImgList(formattedItems);
    } catch (error) {
      console.error(`Ocurrió un error: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {imgList && imgList.length > 0 && (
            <div className="homeBanner container">
              <CarouselObj imgList={imgList} isHome={1} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

// {props.linkTo ? (
//   <div className="padreDeA">
//     {/* Devuelve un warning sobre anidacion de <a>, pero los workarounds no tienen sentido.Va a quedar asi con el warning*/}
//     <Link to={`/products/${props.id}`} style={{ textDecoration: "none" }}>
//       <>{itemContent}</>
//     </Link>
//   </div>
// ) : (
//   itemContent
// )}

export default Home;
