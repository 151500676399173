import Spinner from "../Spinner/Spinner";
import { Accordion, Alert } from "react-bootstrap";
import Tutorial from "../Tutorial/Tutorial";
import { getTutorials } from "../../../services/db";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./TutorialContainer.css";

function TutorialContainer() {
  const [loading, setLoading] = useState(false);
  const [tutorials, setTutorials] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueModels, setUniqueModels] = useState([]);
  const brandUrl =
    "https://res.cloudinary.com/dsznajbru/image/upload/v1683729507/Telpower/logo_dpqlhq.png";
  let productLink = "";

  useEffect(() => {
    setLoading(true);
    getTutorials()
      .then((resp) => {
        setTutorials(resp);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching tutorials:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Obtener las categorías únicas
    const categoriesSet = new Set(tutorials.map((tutorial) => tutorial.categ));
    setUniqueCategories([...categoriesSet]);

    // Obtener los modelos únicos
    const modelsSet = new Set(tutorials.map((tutorial) => tutorial.model));
    setUniqueModels([...modelsSet]);
  }, [tutorials]);

  return (
    <div className="container" style={{ marginTop: "20px" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <div style={{ textAlign: "center" }}>
            <img className="brandTutorial" src={brandUrl} alt="" />
          </div>
          <Accordion>
            {uniqueCategories.map((category, categoryIndex) => {
              const filteredModels = uniqueModels.filter((model) =>
                tutorials.some(
                  (tutorial) =>
                    tutorial.categ === category && tutorial.model === model
                )
              );

              if (filteredModels.length === 0) {
                return null; // No hay modelos que cumplan el filtro, omitir el Accordion.Item
              }

              return (
                <Accordion.Item
                  key={categoryIndex}
                  eventKey={categoryIndex.toString()}
                >
                  <Accordion.Header>{category}</Accordion.Header>
                  <Accordion.Body>
                    {filteredModels.map((model, modelIndex) => {
                      const filteredTutorials = tutorials.filter(
                        (tutorial) =>
                          tutorial.categ === category &&
                          tutorial.model === model
                      );

                      return (
                        <Accordion
                          key={categoryIndex.toString() + modelIndex.toString()}
                          style={{ marginTop: "10px" }}
                        >
                          <Accordion.Item
                            eventKey={`model-${categoryIndex.toString()}-${modelIndex.toString()}`}
                            style={{ marginBottom: "10px" }}
                          >
                            <Accordion.Header>{model}</Accordion.Header>
                            <Accordion.Body>
                              <div className="container transparentDiv2">
                                {filteredTutorials.map((tutorial) =>
                                  tutorial.tutorialsData.map(
                                    (tutorialsData) => (
                                      <div
                                        className="container"
                                        key={`-${categoryIndex.toString()}-${modelIndex.toString()}}`}
                                      >
                                        <Tutorial
                                          title={tutorialsData.title}
                                          videoId={tutorialsData.videoId}
                                          key={`tutorial-${categoryIndex.toString()}-${modelIndex.toString()}}`}
                                          productLink={
                                            (productLink =
                                              tutorial.product._key.path
                                                .segments[6])
                                          }
                                        />
                                        <Alert
                                          key={
                                            categoryIndex.toString() +
                                            modelIndex.toString()
                                          }
                                          variant={"dark"}
                                        />
                                      </div>
                                    )
                                  )
                                )}
                                <h6
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  <p>Ver producto: </p>
                                  <Link
                                    to={`/products/${productLink}`}
                                    style={{
                                      textDecoration: "underline",
                                      color: "white",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {model}
                                  </Link>
                                </h6>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      )}
    </div>
  );
}

export default TutorialContainer;
