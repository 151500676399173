import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faSearchLocation,
  faMailBulk,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import FooterButton from "../FooterButton/FooterButton";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useItemModel } from "../Context/ItemModelContext/ItemModelContext";
import "./Footer.css";
import logo from "../../img/logoWppTp.png";

function Footer() {
  const [showFooter, setShowFooter] = useState(false);
  const location = useLocation();
  const [chatMessage, setChatMessage] = useState();
  let footerButton;
  let wppButton;
  const { itemModel } = useItemModel();
  useEffect(() => {
    const inputElements = document.getElementsByClassName(
      "styles-module_input__WFb9L"
    );

    const fetchData = async () => {
      if (location.pathname.startsWith("/products/")) {
        // const productId = location.pathname.split("/")[2];
        if (itemModel) {
          setChatMessage(`Hola! tengo una consulta sobre "${itemModel}"`);
          inputElements[0].value = `Hola! tengo una consulta sobre "${itemModel}"`;
        }
      } else {
        setChatMessage("Hola! tengo una consulta");
        inputElements[0].value = "Hola! tengo una consulta";
      }
    };
    fetchData();
  }, [location, chatMessage, itemModel]);
  const toggleFooter = () => {
    setShowFooter(!showFooter);
    footerButton = document.querySelector(".footer-button");
    wppButton = document.querySelector(".styles-module_whatsappButton__tVits");
    if (showFooter) {
      footerButton.classList.add("repositionButton");
      wppButton.classList.add("repositionWpp");
    } else {
      footerButton.classList.remove("repositionButton");
      wppButton.classList.remove("repositionWpp");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <FloatingWhatsApp
        style={{ bottom: "5rem" }}
        phoneNumber="+541124749315"
        accountName="Telpower S.A."
        statusMessage="Soporte técnico."
        chatMessage="Hola, ¿Cómo podemos ayudarte?"
        placeholder="Escribe un mensaje..."
        avatar={logo}
        darkMode={false}
        messageDelay={0.7} //es en segundos, no en milisegundos
        allowClickAway="true"
        allowEsc="true"
      />
      <FooterButton
        style={{ zIndex: 1000 }}
        showFooter={showFooter}
        toggleFooter={toggleFooter}
        faArrowUp={faArrowUp}
        faArrowDown={faArrowDown}
      />
      {showFooter ? (
        <footer className="fixed-bottom footer">
          <Container className="footer">
            <Row className="justify-content-center text-center">
              <p style={{ paddingTop: "1.25rem" }}>
                <a href="#top">Volver</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhoneAlt} style={{ color: "white" }} />{" "}
                <a href=""> &nbsp; (011)-4752-0079</a>
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faSearchLocation}
                  style={{ color: "white" }}
                />{" "}
                <a
                  href="https://www.google.com.ar/maps/place/Telpower+S.A./@-34.5653813,-58.5186738,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb71b3cba5323:0x286eb1fc2a84adfb!8m2!3d-34.5653813!4d-58.5164798"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp; Calle 48 (ex Estrada) 1335 - Villa Maipú - Pdo. de
                  Gral. San Martín
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faMailBulk} style={{ color: "white" }} />{" "}
                <a href="mailto:soporte@telpower.com.ar">
                  &nbsp;soporte@telpower.com.ar
                </a>
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "white" }}
                />{" "}
                <a href="https://www.instagram.com/telpowersa/">@telpowersa</a>
              </p>
            </Row>
          </Container>
        </footer>
      ) : (
        <footer className="fixed-bottom footer hidden">
          <Container className="footer hidden">
            <p style={{ paddingTop: "1.25rem" }}>
              <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <FontAwesomeIcon icon={faPhoneAlt} style={{ color: "white" }} />{" "}
                <a href=""> &nbsp;(011)-4752-0079</a>
              </span>
              ||
              <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <FontAwesomeIcon
                  icon={faSearchLocation}
                  style={{ color: "white" }}
                />{" "}
                <a
                  href="https://www.google.com.ar/maps/place/Telpower+S.A./@-34.5653813,-58.5186738,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb71b3cba5323:0x286eb1fc2a84adfb!8m2!3d-34.5653813!4d-58.5164798"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Calle 48 (ex Estrada) 1335 - Villa Maipú - Pdo. de Gral.
                  San Martín
                </a>
              </span>
              ||
              <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <FontAwesomeIcon icon={faMailBulk} style={{ color: "white" }} />{" "}
                <a href="mailto:soporte@telpower.com.ar">
                  &nbsp; soporte@telpower.com.ar
                </a>
              </span>
              ||
              <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "white" }}
                />{" "}
                <a href="https://www.instagram.com/telpowersa/">
                  {" "}
                  &nbsp;@telpowersa
                </a>
              </span>
            </p>
          </Container>
        </footer>
      )}
    </div>
  );
}

export default Footer;
