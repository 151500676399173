const prodData = [
  {
    index: 16,
    title: "Alineadora 3D L12000",
    descr:
      "La alineadora 3D posee cámaras de eficiente tecnología y sencilla utilización, con un diseño que permite ahorrar espacio; esta alineadora es de interés para todo tipo de taller, negocio de neumáticos y para los que se inician en el mercado.\n\n- Para autos / Pick up / Furgones.",
    miniDescr: "Alineadora 3D Computarizada",
    categ: "Alineadora",
    model: "L12000 3D",
    img: {
      0: "https://res.cloudinary.com/dsznajbru/image/upload/c_thumb,g_face/v1684952008/Telpower/alineadora_3d_front_ekkyip.png.png",
      1: "https://res.cloudinary.com/dsznajbru/image/upload/c_thumb,g_face/v1684952008/Telpower/alineadora_3d_side_vcwdgp.png.png",
      2: "https://res.cloudinary.com/dsznajbru/image/upload/c_thumb,g_face/v1684952008/Telpower/alineadora_3d_marcador_large_hsagic.png",
      3: "https://res.cloudinary.com/dsznajbru/image/upload/c_thumb,g_face/v1684952008/Telpower/alineadora_3d_marcador_small_tni3yv.png",
    },
    descrPlus:
      "- Alta precisión, de respuesta rápida.\n\n- Software de avanzada.\n\n- Base de datos de vehículos.\n\n- Base de datos para clientes.\n\n- Distancia súper corta para empujar el coche, de ida y vuelta.\n\n- Compensación de alabeo (Para las llantas dañadas).\n\n- Medición de datos de cada rueda.\n\n- Sin cables entre objetivos y sistema de cámaras.\n\n- Es posible un ingreso directo de las especificaciones de los vehículos para los autos especiales.\n\n- Lectura en el monitor  de manera simultánea del ángulo de empuje, divergencia en las curvas, las ruedas, comba y convergencia.",
    table: {
      headers: [
        "Especificaciones técnicas",
        "Precisión",
        "Rango de medición",
        "Rango total de medición",
      ],
      rows: [
        ["Convergencia total", "±2′", "±4°", "±20°"],
        ["Convergencia Individual", "±1′", "±2°", "±10°"],
        ["Comba", "±1′", "±4°", "±10°"],
        ["Avance", "±3′", "±18°", "±22°"],
        ["KPI", "±3′", "±18°", "±22°"],
        ["Setback", "±1′", "±2°", "±10°"],
        ["Angulo de empuje", "±1′", "±2°", "±10°"],
        ["Diferencia distancia entre ejes", "±2′", "±4°", "±20°"],
        ["Diferencia de trocha", "±2′", "±4°", "±20°"],
      ],
    },
  },
];

export default prodData;
