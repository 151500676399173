import "./Item.css";
import { Link } from "react-router-dom";
import Carousel from "../Carousel/Carousel";

function Item(props) {
  const itemContent = (
    <>
      {props.linkTo ? (
        <div
          key={props.id}
          className="product-card "
          style={{ minHeight: "644px" }}
        >
          <Carousel imgList={[props]} isHome={0} limit={1} />
          <h3>{props.title}</h3>
          <p>{props.miniDescr}</p>
        </div>
      ) : (
        <div key={props.id} className="product-card ">
          <Carousel imgList={[props]} isHome={0} limit={0} />
          <h3>{props.title}</h3>
          <p>{props.miniDescr}</p>
        </div>
      )}
    </>
  );
  return (
    <div>
      {props.linkTo ? (
        <div style={{ minHeight: "644px" }}>
          {/* Devuelve un warning sobre anidacion de <a>, pero los workarounds no tienen sentido.Va a quedar asi con el warning*/}
          <Link to={`/products/${props.id}`} style={{ textDecoration: "none" }}>
            <>{itemContent}</>
          </Link>
        </div>
      ) : (
        itemContent
      )}
    </div>
  );
}

export default Item;
