import ReactPlayer from "react-player/youtube";
import "./videoPlayer.css";
function VideoPlayer(props) {
  return (
    <div
      className="player-wrapper container"
      style={{ border: "5px solid black", borderRadius: "10px" }}
    >
      <ReactPlayer
        className="react-player"
        url={`https://www.youtube.com/watch?v=${props.videoId}`}
        controls={true}
        light={true}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default VideoPlayer;
