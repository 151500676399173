import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import "./Carousel.css";

function CarouselObj(props) {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const handleShareClick = () => {
    setShareModalOpen(!shareModalOpen);
  };
  if (props.isHome === 1) {
    return (
      <Carousel
        className="carouselHomeDiv"
        interval={Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000}
      >
        {props.imgList.map((item) => (
          <Carousel.Item key={item.id}>
            <Link
              to={`/products/${item.id}`}
              style={{ textDecoration: "none" }}
            >
              <img
                key={item.id}
                id={item.id}
                className="d-block"
                src={item.src}
                alt={item.altText}
              />
              <Carousel.Caption className={item.className}>
                <h3>
                  {" "}
                  <span
                    style={{
                      boxShadow: "2px 5px 10px rgba(12, 47, 48, 0.952)",
                      backgroundColor: "rgba(12, 47, 48, 0.952)",
                    }}
                  >
                    {item.caption}
                  </span>
                </h3>
                {/* <p>
                  <span
                    style={{
                      boxShadow: "2px 5px 10px rgba(12, 47, 48, 0.952)",
                      backgroundColor: "rgba(12, 47, 48, 0.952)",
                    }}
                  >
                    {item.description}
                  </span>
                </p> */}
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  } else {
    const imgUrls = props.imgList[0].img;
    const altText = props.imgList[0].title;
    if (imgUrls)
      if (
        Object.keys(imgUrls).length <= 10 &&
        Object.keys(imgUrls).length > 1
      ) {
        if (areImageURLsValid(Object.values(imgUrls))) {
          if (props.limit === 1) {
            return (
              <Carousel
                className="carouselProductsDiv"
                controls={false}
                indicators={false}
              >
                {props.imgList.map((item) => (
                  <Carousel.Item key={item.id}>
                    <img
                      className="d-block w-100"
                      src={item.img[0]}
                      alt={`${item.altText}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            );
          }
          return (
            <div>
              <Carousel className="carouselDetailDiv">
                {Object.values(imgUrls).map((imgUrl, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      style={{ width: "350px", height: "350px" }}
                      src={imgUrl}
                      alt={`Imagen ${altText}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div
                className="container"
                style={{
                  display: "flex",
                  position: "relative",
                  width: "auto",
                  justifyContent: "flex-end",
                  marginTop: "-4rem",
                  marginBottom: "4rem",
                  marginRight: "1.25rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faShareAlt}
                  style={{
                    color: "white",
                    fontSize: "24px",
                    zIndex: "9999",
                    cursor: "pointer",
                  }}
                  onClick={handleShareClick}
                />
              </div>
              {shareModalOpen && (
                <div
                  className="container"
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "auto",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "-3.25rem",
                  }}
                >
                  {/* <FacebookShareButton
                    className="swal-social-button"
                    url={window.location.href}
                    hashtag={`#+${props.imgList[0].categ}`}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton> */}
                  {/* <FacebookMessengerShareButton
                    className="swal-social-button"
                    url={window.location.href}
                    hashtag={`#+${props.imgList[0].categ}`}
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton> */}
                  {/* <TwitterShareButton
                    className="swal-social-button"
                    url={window.location.href}
                    title={(props.imgList[0].title, "Telpower S.A")}
                    hashtag={
                      (`#+${props.imgList[0].categ}`,
                      "#Argentina",
                      "#TelpowerSA")
                    }
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton> */}
                  <WhatsappShareButton
                    className="swal-social-button"
                    url={window.location.href}
                    test={props.imgList[0].title}
                    title={`${props.imgList[0].title} Telpower S.A`}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    className="swal-social-button"
                    url={window.location.href}
                    title={`${props.imgList[0].title} Telpower S.A`}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                  <EmailShareButton
                    className="swal-social-button"
                    subject={`${props.imgList[0].title} Telpower S.A`}
                    url={window.location.href}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              )}
            </div>
          );
        }
      } else {
        return (
          <div>
            <img src={Object.values(imgUrls)[0]} alt={"Imagen"} />
          </div>
        ); // Otra lógica o componente a mostrar en caso de que la longitud sea mayor a 10
      }
  }
}

function areImageURLsValid(urlArray) {
  const imageRegex = /\.(jpeg|jpg|gif|png|svg)$/i;

  for (let i = 0; i < urlArray.length; i++) {
    if (!imageRegex.test(urlArray[i])) {
      return false; // Si una URL no cumple con el formato, retorna falso inmediatamente
    }
  }

  return true; // Todas las URLs son válidas
}

export default CarouselObj;
