import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Home from "../Home/Home";
//import Help from "../Help/Help";
import Footer from "../Footer/Footer";
import ItemListContainer from "../ItemListContainer/ItemListContainer";
import ItemDetailContainer from "../ItemDetailContainer/ItemDetailContainer";
import "./Router.css";
import ContactContainer from "../ContactContainer/ContactContainer";
import TutorialContainer from "../TutorialContainer/TutorialContainer.jsx";

function Router() {
  return (
    <>
      <BrowserRouter>
        <div className="backgr">
          <div>
            <NavBar />
          </div>
          <div className="content">
            <Routes>
              <Route
                path="/"
                element={
                  <div>
                    <Home />
                  </div>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <div>
                    <ContactContainer />
                  </div>
                }
              />
              {/* <Route
                path="/help"
                element={
                  <div>
                    <Help />
                  </div>
                }
              /> */}
              <Route
                path="/tutorials"
                element={
                  <div>
                    <TutorialContainer />
                  </div>
                }
              />
              <Route
                path="/products"
                element={
                  <div>
                    <ItemListContainer />
                  </div>
                }
              />
              <Route
                path="/products/alineadoras"
                element={
                  <div>
                    <ItemListContainer cat="Alineadora" />
                  </div>
                }
              />
              <Route
                path="/products/balanceadoras"
                element={
                  <div>
                    <ItemListContainer cat="Balanceadora" />
                  </div>
                }
              />
              <Route
                path="/products/desarmadoras"
                element={
                  <div>
                    <ItemListContainer cat="Desarmadora" />
                  </div>
                }
              />
              <Route
                path="/products/accesorios"
                element={
                  <div>
                    <ItemListContainer cat="Accesorio" />
                  </div>
                }
              />
              <Route
                path="/search/:query?"
                element={
                  <div>
                    <ItemListContainer />
                  </div>
                }
              />
              <Route
                exact
                path="/products/:id"
                element={
                  <div>
                    <ItemDetailContainer />
                  </div>
                }
              />
              <Route
                path="*"
                element={
                  <div
                    className="container"
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "100px",
                    }}
                  >
                    <h1>Error 404: Page not found</h1>
                  </div>
                }
              />
            </Routes>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
      
    </>
  );
}

export default Router;
