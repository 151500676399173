import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ItemDetail from "../ItemDetail/ItemDetail";
import Spinner from "../Spinner/Spinner";
import { getItem } from "../../../services/db";
import { useItemModel } from "../Context/ItemModelContext/ItemModelContext";

function ItemDetailContainer() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setItemModel } = useItemModel();

  let { id } = useParams();

  useEffect(() => {
    async function fetchProduct() {
      setLoading(true);
      try {
        const product = await getItem(id);
        setProduct(product);
        setLoading(false);
        if (product.title) {
          setItemModel(product.title);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchProduct();
  }, [id, setItemModel]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      ) : product.length !== 0 ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ItemDetail
            key={product?.id}
            id={product?.id}
            title={product?.title}
            img={product?.img}
            categ={product?.categ}
            model={product?.model}
            descr={product?.descr}
            miniDescr={product?.miniDescr}
            descrPlus={product?.descrPlus}
            table={product?.table}
            linkTo={false}
          />
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h1 style={{ fontWeight: "bold" }}>No existe el producto</h1>{" "}
          <Link
            to="/"
            style={{
              color: "white",
              textDecoration: "underline",
              fontWeight: "bold",
              marginTop: "50px",
            }}
          >
            Ir al Inicio
          </Link>
        </div>
      )}
    </div>
  );
}

export default ItemDetailContainer;
