import React, { useState, useEffect } from "react";
import Item from "../Item/Item";
import "./ItemDetail.css";
import { Table } from "react-bootstrap";

function ItemDetail(props) {
  const [tableHeaders, setTableHeaders] = useState(["Headers"]);
  const [tableRows, setTableRows] = useState([["Row"]]);
  useEffect(() => {
    async function fetchData() {
      if (props?.table) {
        const headers = props.table.headers;
        const rows = props.table.rows;
        setTableHeaders(headers);
        setTableRows(rows);
      }
    }

    fetchData();
  }, [props?.table]);

  return (
    <div className="container">
      <div className="item-detail-portrait">
        <div>
          <Item
            key={props?.id}
            id={props?.id}
            title={props?.title}
            img={props?.img}
            categ={props?.categ}
            model={props?.model}
            descr={props?.descr}
            miniDescr={props?.miniDescr}
            descrPlus={props?.descrPlus}
            className="item-detail-item container"
            linkTo={false}
          />
        </div>
      </div>

      {props?.descr && props?.descr.trim() !== "" && (
        <div
          className="item-detail text-center mx-auto"
          style={{ color: "white" }}
        >
          <p>{props.descr}</p>
        </div>
      )}

      {props?.descrPlus && props?.descrPlus.trim() !== "" && (
        <div
          className="item-detail text-left mx-auto"
          style={{ color: "white" }}
        >
          <p>{props?.descrPlus}</p>
        </div>
      )}

      {tableHeaders && tableHeaders.length > 0 && (
        <div
          className="item-detail text-center mx-auto"
          style={{ color: "white", overflow: "auto" }}
        >
          <Table style={{ "--bs-table-bg": "" }} striped bordered hover>
            <thead style={{ color: "white", background: "#28393a" }}>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.entries(tableRows).map(([_, row], rowIndex) => (
                <tr key={rowIndex} style={{ color: "white" }}>
                  {tableHeaders.map((header, fieldIndex) => (
                    <td key={fieldIndex} style={{ color: "white" }}>
                      {header === "Datos" || header === "Descripción" ? (
                        // Hago que la tabla escriba los <br> que estan en los datos en la db como newLines

                        <div style={{ whiteSpace: "pre-line" }}>
                          {row[header].replace(/<br>/g, "\n")}
                        </div>
                      ) : (
                        row[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default ItemDetail;
