import "./App.css";
import "./resources/components/NavBar/NavBar";
import Router from "./resources/components/Router/Router";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
//import { addProducts } from "./services/db.js";
import Notification from "./resources/components/Notification/Notification";
const date = new Date();

function App() {
  const [, setCookie] = useCookies(["Telpower.com.ar"]);
  useEffect(() => {
    // Generar la cookie al cargar la página
    setCookie("Telpower.com.ar", "value", {
      sameSite: "none",
      secure: true,
    });
  }, [setCookie]);
  //addProducts();
  return (
    <div>
      {(date.getMonth() + 1 >= 11 || date.getMonth() + 1 === 1) && (
        <Notification
          title="Vacaciones!"
          description="No estaremos atendiendo desde el 05/02/2023 hasta el 13/02/2024"
        />
      )}
      <Router className="backgr" />
    </div>
  );
}

export default App;
