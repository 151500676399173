import "./NavBar.css";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Search from "../Search/Search";
import { LinkContainer } from "react-router-bootstrap";
import React from "react";

const brandUrl =
  "https://res.cloudinary.com/dsznajbru/image/upload/v1683729507/Telpower/logo_dpqlhq.png";

function NavBar() {
  return (
    <Navbar bg="dark" variant="dark" expand="sm" className="bg-body-tertiary" collapseOnSelect >
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="navbar-brand"
              src={brandUrl}
              width="100"
              height="50"
              alt="Logo TelPower"
              
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            <LinkContainer to="/">
              <Nav.Link>Inicio</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Productos" id="basic-nav-dropdown">
              <LinkContainer to="/products">
                <NavDropdown.Item>Todos los productos</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/products/alineadoras">
                <NavDropdown.Item>Alineadoras</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/products/balanceadoras">
                <NavDropdown.Item>Balanceadoras</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/products/desarmadoras">
                <NavDropdown.Item>Desarmadoras</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/products/accesorios">
                <NavDropdown.Item>Accesorios</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/tutorials">
              <Nav.Link>Tutoriales</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact-us">
              <Nav.Link>Contacto</Nav.Link>
            </LinkContainer>
          </Nav>
          <div className="d-flex">
            <Search />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
