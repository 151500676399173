import { React, useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import Trie from "../../../services/trie.js";
import { getItemsByQuery } from "../../../services/db";
import { useNavigate } from "react-router-dom";
import "./Search.css";

function Search() {
  const [prefix, setPrefix] = useState("");
  const [, setSuggestionsText] = useState("");
  const [suggestionsArray, setSuggestionsArray] = useState([]);

  const myTrieRef = useRef(new Trie());
  const navigate = useNavigate();
  useEffect(() => {
    async function populateTrieAsync() {
      const dictionary = await getWords();
      const words = dictionary.words;
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        myTrieRef.current.insert(word);
      }
      setSuggestionsText(() => dictionary.words.join(" "));
      setSuggestionsArray(() => dictionary.words);
    }
    populateTrieAsync();
  }, []);

  const getWords = async () => {
    const products = await getItemsByQuery([]);
    const names = products.map((product) => product.title);
    const words = [...names];
    return { words };
  };

  (async () => {
    const dictionary = await getWords();
    const words = dictionary.words;
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      myTrieRef.current.insert(word);
    }
  })();

  const onChange = (e) => {
    var value = e.target.value;
    setPrefix(value);
    var words = value.split(" ");
    var trie_prefix = words[words.length - 1].toLowerCase();
    var found_words = myTrieRef.current.find(trie_prefix).sort((a, b) => {
      return a.length - b.length;
    });
    var first_word = found_words[0];
    if (
      found_words.length !== 0 &&
      value !== "" &&
      value[value.length - 1] !== " "
    ) {
      if (first_word != null) {
        var remainder = first_word.slice(trie_prefix.length);
        setSuggestionsText(value + remainder);
      }
    } else {
      setSuggestionsText(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      setPrefix(suggestionsArray[0] || "");
    }
  };

  const handleSearch = async () => {
    const queries = prefix.split(" ").map((term) => term.toLowerCase());
    const query = queries.join("+");
    const encodedQuery = encodeURIComponent(query); // Codifica el término de búsqueda
    navigate(`/search/${encodedQuery}`);
  };

  return (
    <div className="d-flex">
      <Form
        className="d-flex"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <Form.Control
          type="text"
          placeholder="Buscar"
          className="me-2"
          aria-label="Buscar"
          name="search"
          id="search"
          list="suggestions"
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        <datalist id="suggestions">
          {suggestionsArray
            .filter((word) =>
              word.toLowerCase().startsWith(prefix.toLowerCase())
            )
            .slice(0, 5) // Limitar la cantidad de sugerencias mostradas
            .map((word, index) => (
              <option value={word} key={index} />
            ))}
        </datalist>
        <Button
          variant="outline-success"
          className="Footer-button btn btn-primary"
          style={{ color: "white" }}
          type="submit"
        >
          Buscar
        </Button>
      </Form>
    </div>
  );
}

export default Search;
