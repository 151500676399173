import { createMessage } from "../../../services/contactService";
import swal from "sweetalert";
import "./contactContainer.css";
import Contact from "../Contact/Contact";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

function ContactContainer() {
  const [showContact, setShowContact] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  useEffect(() => {
    setCaptchaValue(Math.floor(Math.random() * 10000));
  }, []);

  function handleKeyDown(evt) {
    if (evt.keyCode === 13) {
      handleConfirmation(evt);
    }
  }

  async function handleSubmit(userData, evt) {
    evt.preventDefault();
    const messageData = {};
    Object.entries(userData).forEach(([key, value]) => {
      messageData[key] = value.value;
    });
    await createMessage(messageData);
    await swal({
      title: "Gracias por tu mensaje!",
      text: `Fue recibido correctamente`,
      icon: "success",
    });
    setShowContact(false);
    setCaptchaValue(Math.floor(Math.random() * 10000));
    setShowConfirmation(true);
  }

  function handleConfirmation(evt) {
    evt.preventDefault();
    if (inputValue === `${captchaValue}`) {
      setShowConfirmation(false);
      setShowContact(true);
    } else {
      setInputValue("");
    }
  }

  return (
    <>
      {showConfirmation && (
        <div className="form-container">
          <div htmlFor="confirmation-input">
            <p>
              Escribe
              <label
                className={Math.random().toString().slice(2, 8)}
                style={{
                  color: "white",
                  fontSize: "30px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {captchaValue}
              </label>
              para continuar:
            </p>
          </div>
          <input
            id="confirmation-input"
            className={Math.random().toString().slice(2, 8)}
            type="text"
            value={inputValue}
            onChange={(evt) => setInputValue(evt.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button variant="primary" type="submit" onClick={handleConfirmation}>
            Confirmar
          </Button>
        </div>
      )}
      {!showConfirmation && showContact && (
        <Contact
          handleSubmit={handleSubmit}
          buttonText={"Enviar"}
          showSubject={true}
        />
      )}
    </>
  );
}

export default ContactContainer;
