const urlPhp = "/scripts/messageHandler.php/";
export async function createMessage(contactInfo) {
  const response = fetch(urlPhp, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contactInfo),
  });

  const data = await response;

  return data;
}
