import React from "react";
import Item from "../Item/Item";
//import "./itemList.css"; Por si lo necesito a futuro

function ItemList(props) {
  return (
    <Item
      id={props.id}
      title={props.title}
      img={props.img}
      categ={props.gen}
      model={props.price}
      descr={props.desc}
      miniDescr={props.miniDescr}
      linkTo={true}
    />
  );
}

export default ItemList;
