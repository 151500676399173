import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FooterButton.css";

function FooterButton({ showFooter, toggleFooter, faArrowUp, faArrowDown }) {
  return (
    <Button
      style={{ zIndex: "9999" }}
      className="footer-button position-fixed  me-3 mb-3 "
      onClick={toggleFooter}
    >
      {showFooter ? (
        <FontAwesomeIcon icon={faArrowDown} />
      ) : (
        <FontAwesomeIcon icon={faArrowUp} />
      )}
    </Button>
  );
}

export default FooterButton;
